import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "list_head d-flex" }
const _hoisted_2 = { class: "list_name" }
const _hoisted_3 = ["disabled", "value"]
const _hoisted_4 = { class: "list_description mb-3" }
const _hoisted_5 = ["disabled", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, [
        _createElementVNode("input", {
          type: "text",
          maxlength: "20",
          disabled: _ctx.disabled,
          value: _ctx.title,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:title', ($event.target as HTMLInputElement).value)))
        }, null, 40, _hoisted_3)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("textarea", {
        class: "form-control",
        cols: "30",
        rows: "4",
        disabled: _ctx.disabled,
        maxlength: "200",
        placeholder: "Add description",
        value: _ctx.description,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:description', ($event.target as HTMLInputElement).value)))
      }, null, 40, _hoisted_5),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-right" }, null, -1))
    ])
  ], 64))
}