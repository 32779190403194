import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/images/delete.svg'


const _hoisted_1 = { class: "my-topics" }
const _hoisted_2 = { class: "topic_info" }
const _hoisted_3 = { class: "topic_inner" }
const _hoisted_4 = { class: "article_image" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "topics_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "my_info" }, [
        _createElementVNode("div", {
          class: "avtar",
          style: {"display":"block"}
        }, [
          _createElementVNode("div", { class: "avtar_name" }, [
            _createElementVNode("p", null, "TP")
          ])
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: {
              name: 'Topic',
              params: { slug: _ctx.slug, tab: _ctx.TopicPageTab.QUESTION },
            }
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.image,
                alt: _ctx.title
              }, null, 8, _hoisted_5)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (!_ctx.disabled)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "delete_list",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onDelete()))
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Delet List"
                }, null, -1)
              ])))
            : _createCommentVNode("", true),
          _createVNode(_component_router_link, {
            to: {
              name: 'Topic',
              params: { slug: _ctx.slug, tab: _ctx.TopicPageTab.QUESTION },
            }
          }, {
            default: _withCtx(() => [
              _createElementVNode("h5", null, _toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }, 8, ["to"]),
          _cache[2] || (_cache[2] = _createElementVNode("span", null, "Topic", -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
        ])
      ])
    ])
  ]))
}