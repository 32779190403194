import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/delete.svg'


const _hoisted_1 = {
  key: 0,
  class: "my_topics_lists list-deleteBTN"
}
const _hoisted_2 = { class: "my_list_inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_topics_header = _resolveComponent("my-topics-header")!
  const _component_my_topic_card = _resolveComponent("my-topic-card")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_ctx.currentList)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onDeleteList())),
          class: "delete_list"
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Delet List"
          }, null, -1)
        ])),
        _createVNode(_component_my_topics_header, {
          title: _ctx.currentList.title,
          "onUpdate:title": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentList.title) = $event)),
          description: _ctx.currentList.description,
          "onUpdate:description": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentList.description) = $event)),
          disabled: false
        }, null, 8, ["title", "description"]),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentList.topic_list_topics, (topic) => {
            return (_openBlock(), _createBlock(_component_my_topic_card, {
              key: topic.topics.id,
              description: topic.topics.description,
              image: topic.topics.image,
              title: topic.topics.title,
              slug: topic.topics.slug,
              disabled: false,
              onOnDelete: ($event: any) => (_ctx.onRemoveTopicFromList(topic.topics.id))
            }, null, 8, ["description", "image", "title", "slug", "onOnDelete"]))
          }), 128))
        ]),
        _createVNode(_component_pagination, {
          currentPage: _ctx.currentPage,
          totalPages: _ctx.totalPages,
          onOnChangePage: _ctx.setPage
        }, null, 8, ["currentPage", "totalPages", "onOnChangePage"])
      ]))
    : _createCommentVNode("", true)
}