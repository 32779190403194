import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/topic_list.svg'
import _imports_1 from '@/assets/images/plus.svg'


const _hoisted_1 = { class: "col-md-4 mb-5" }
const _hoisted_2 = { class: "top_sidebar topics_card" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "list-sec" }
const _hoisted_5 = { class: "add_btn add-question-btn" }
const _hoisted_6 = { class: "add_topic_list" }
const _hoisted_7 = { class: "p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tab_button = _resolveComponent("tab-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("h2", null, "Topics", -1)),
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", null, [
          _createVNode(_component_tab_button, {
            icon_inactive: require('@/assets/images/my_topic_icon-black.svg'),
            icon_active: require('@/assets/images/my_topic_icon.svg'),
            title: "My Topics",
            onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChangeTab(_ctx.TopicsPageTab.MY_TOPICS))),
            active: _ctx.currentTab === _ctx.TopicsPageTab.MY_TOPICS
          }, null, 8, ["icon_inactive", "icon_active", "active"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_tab_button, {
            icon_inactive: require('@/assets/images/view.svg'),
            icon_active: require('@/assets/images/view-orange.svg'),
            title: "Viewed Topics",
            onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeTab(_ctx.TopicsPageTab.VIEWED))),
            active: _ctx.currentTab === _ctx.TopicsPageTab.VIEWED
          }, null, 8, ["icon_inactive", "icon_active", "active"])
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_tab_button, {
            icon_inactive: require('@/assets/images/pencil-black.svg'),
            icon_active: require('@/assets/images/pencil.svg'),
            title: "Edited Topics",
            onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChangeTab(_ctx.TopicsPageTab.EDITED))),
            active: _ctx.currentTab === _ctx.TopicsPageTab.EDITED
          }, null, 8, ["icon_inactive", "icon_active", "active"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("p", null, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }),
            _createTextVNode(" List")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "cmn_topic_heading cm_bg",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onCreateList()))
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("img", {
                src: _imports_1,
                alt: "Create Icon"
              }, null, -1)
            ]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("ul", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userTopicLists, (list) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "",
              key: list.id
            }, [
              _createVNode(_component_tab_button, {
                title: list.title,
                onOnClick: ($event: any) => (_ctx.onSelectUserList(list.id)),
                active: list.selected
              }, null, 8, ["title", "onOnClick", "active"])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}