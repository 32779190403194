import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "my_topics_lists" }
const _hoisted_2 = { class: "my_topics_outer" }
const _hoisted_3 = {
  key: 0,
  class: "add_interest"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_topics_header = _resolveComponent("my-topics-header")!
  const _component_my_topic_card = _resolveComponent("my-topic-card")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_my_topics_header, {
      title: _ctx.listTitle,
      "onUpdate:title": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.listTitle) = $event)),
      description: _ctx.listDescription,
      "onUpdate:description": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.listDescription) = $event)),
      disabled: true
    }, null, 8, ["title", "description"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isRandom && !_ctx.isPageLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[3] || (_cache[3] = _createElementVNode("p", null, "We Need to Know Your Interests For Selecting The Best Topics", -1)),
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goProfile && _ctx.goProfile(...args)))
            }, "Complete Your Profile")
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topics, (topic) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: topic.id
        }, [
          (topic.topic_list_topics)
            ? (_openBlock(), _createBlock(_component_my_topic_card, {
                key: 0,
                description: topic.description,
                title: topic.title,
                disabled: true
              }, null, 8, ["description", "title"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    (_ctx.topics.length > 0)
      ? (_openBlock(), _createBlock(_component_pagination, {
          key: 0,
          currentPage: _ctx.currentPage,
          totalPages: _ctx.totalPages,
          onOnChangePage: _ctx.setPage
        }, null, 8, ["currentPage", "totalPages", "onOnChangePage"]))
      : _createCommentVNode("", true)
  ]))
}